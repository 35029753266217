<template>
	<div class="assessBox humanaffairs-info">
		<div class="app-container" style="background: #fff">
			<formItem
				class="queryForm"
				ref="formItem"
				:formItems="formItems"
				@cancel="$router.go(-1)"
				:defaultData="formModel"
				:showFooter="false"
				style="margin: 10px 0 0 0"
			>
			</formItem>
			<template v-if="allDataList?.length">
				<div class="viewboxDetail" v-for="(item, inx) in allDataList" :key="inx">
					<div class="no-print" style="text-align: right; background: #fff; padding: 0px 30px 0 0">
						<el-button
							size="small"
							v-print="`#printPagebtn_${inx}`"
							type="primary"
							icon="el-icon-receiving"
							v-if="permissionControlBtns(pageName, 'Print')"
							>打印</el-button
						>
					</div>

					<div style="background: #fff; padding: 0px 30px; margin-bottom: 20px" :id="`printPagebtn_${inx}`">
						<div class="baseTable" id="printPage1">
							<table cellspacing="0" cellpadding="0">
								<tr>
									<td class="pic" style="text-align: center; width: 14%" rowspan="13">
										<el-avatar
											shape="square"
											style="width: 80%; height: 100%"
											:src="item.staffBasicInfoDto && item.staffBasicInfoDto.avatarUrl"
										></el-avatar>
										<!-- <img :src="item.staffBasicInfoDto && item.staffBasicInfoDto.avatarUrl" /> -->
										<p>{{ item.staffBasicInfoDto && item.staffBasicInfoDto.name }}</p>
										<p>{{ item.staffBasicJobDetailDto && item.staffBasicJobDetailDto.jobNumber }}</p>
									</td>
									<!-- <td style="text-align: right; background: #f6f8fa">部门</td>
								<td style="text-align: left">
									{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.orgName }}
								</td>
								<td style="text-align: right; background: #f6f8fa">岗位</td>
								<td style="text-align: left">
									{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.jobName }}
								</td>
								<td style="text-align: right; background: #f6f8fa">聘用类型</td>
								<td style="text-align: left">
									{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.hireFormName }}
								</td> -->
								</tr>
								<tr>
									<td style="text-align: right; background: #f6f8fa">部门</td>
									<td style="text-align: left">
										{{ item.staffBasicJobDetailDto && item.staffBasicJobDetailDto.orgName }}
									</td>
									<td style="text-align: right; background: #f6f8fa">岗位</td>
									<td style="text-align: left">
										{{ item.staffBasicJobDetailDto && item.staffBasicJobDetailDto.jobName }}
									</td>
									<td style="text-align: right; background: #f6f8fa; min-width: 100px">员工类型</td>
									<td style="text-align: left">
										{{ item.staffBasicJobDetailDto && item.staffBasicJobDetailDto.hireFormName }}
									</td>
								</tr>
								<tr>
									<td style="text-align: right; background: #f6f8fa">职级</td>
									<td style="text-align: left">
										{{ item.staffBasicJobDetailDto && item.staffBasicJobDetailDto.level }}
									</td>
									<td style="text-align: right; background: #f6f8fa"><div style="min-width: 110px">最近合同开始</div></td>
									<td style="text-align: left">
										{{ item.staffBasicJobDetailDto && item.staffBasicJobDetailDto.contractStartTime }}
									</td>
									<td style="text-align: right; background: #f6f8fa">转正日期</td>
									<td style="text-align: left">
										{{ item.staffBasicJobDetailDto && item.staffBasicJobDetailDto.turnPositiveTime }}
									</td>
								</tr>
								<tr>
									<td style="text-align: right; background: #f6f8fa">职位</td>
									<td style="text-align: left">
										{{ item.staffBasicJobDetailDto && item.staffBasicJobDetailDto.officeName }}
									</td>
									<td style="text-align: right; background: #f6f8fa">最近合同结束</td>
									<td style="text-align: left">
										{{ item.staffBasicJobDetailDto && item.staffBasicJobDetailDto.contractEndTime }}
									</td>
									<td style="text-align: right; background: #f6f8fa">民族</td>
									<td style="text-align: left">{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.nationality }}</td>
								</tr>
								<tr>
									<td style="text-align: right; background: #f6f8fa">员工状态</td>
									<td style="text-align: left">
										{{ item.staffBasicJobDetailDto && item.staffBasicJobDetailDto.typeName }}
									</td>
									<td style="text-align: right; background: #f6f8fa">最高学历</td>
									<td style="text-align: left">
										{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.highestEducationName }}
									</td>
									<td style="text-align: right; background: #f6f8fa">籍贯</td>
									<td style="text-align: left">{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.nativePlace }}</td>
								</tr>
								<tr>
									<td style="text-align: right; background: #f6f8fa"><div style="min-width: 110px">最近晋升日期</div></td>
									<td style="text-align: left">
										{{ item.staffBasicJobDetailDto && item.staffBasicJobDetailDto.promoteTime }}
									</td>
									<td style="text-align: right; background: #f6f8fa">毕业院校</td>
									<td style="text-align: left">
										{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.graduatedSchool }}
									</td>
									<td style="text-align: right; background: #f6f8fa">政治面貌</td>
									<td style="text-align: left">
										{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.politicalName }}
									</td>
								</tr>
								<tr>
									<td style="text-align: right; background: #f6f8fa">招聘渠道</td>
									<td style="text-align: left">
										{{ item.staffBasicJobDetailDto && item.staffBasicJobDetailDto.fromTypeName }}
									</td>
									<td style="text-align: right; background: #f6f8fa">专业</td>
									<td style="text-align: left">{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.profession }}</td>
									<td style="text-align: right; background: #f6f8fa">出生日期</td>
									<td style="text-align: left">{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.birthday }}</td>
								</tr>
								<tr>
									<td style="text-align: right; background: #f6f8fa">入职时间</td>
									<td style="text-align: left">
										{{ item.staffBasicJobDetailDto && item.staffBasicJobDetailDto.entryTime }}
									</td>
									<td style="text-align: right; background: #f6f8fa">毕业年月</td>
									<td style="text-align: left">
										{{ item.staffBasicJobDetailDto && item.staffBasicJobDetailDto.graduatedTime }}
									</td>
									<td style="text-align: right; background: #f6f8fa">婚姻状况</td>
									<td style="text-align: left">{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.maritalName }}</td>
								</tr>
								<tr>
									<td style="text-align: right; background: #f6f8fa">家庭住址</td>
									<td colspan="3" style="text-align: left">
										{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.homeAddress }}
									</td>
									<td style="text-align: right; background: #f6f8fa">家庭电话</td>
									<td style="text-align: left">
										{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.emergencyContactPhone }}
									</td>
								</tr>
								<tr>
									<td style="text-align: right; background: #f6f8fa">邮箱</td>
									<td style="text-align: left" colspan="3">
										{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.mailbox }}
									</td>
									<td style="text-align: right; background: #f6f8fa">手机</td>
									<td style="text-align: left">{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.phoneNumber }}</td>
								</tr>
								<tr>
									<td style="text-align: right; background: #f6f8fa">职责</td>
									<td style="line-height: 20px; text-align: justify; padding: 10px" colspan="5">
										{{ item.staffBasicJobDetailDto && item.staffBasicJobDetailDto.duty }}
									</td>
								</tr>
								<!-- <tr>
								<td style="text-align: right; background: #f6f8fa">主要业务</td>
								<td style="line-height: 20px; text-align: justify; padding: 10px" colspan="5">
									{{ item.staffBasicJobDetailDto && item.staffBasicJobDetailDto.business }}
								</td>
							</tr> -->
								<tr>
									<td style="text-align: right; background: #f6f8fa">其他信息</td>
									<td style="padding: 10px" colspan="5">
										<i
											:class="showOther.includes(inx) ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
											style="cursor: pointer"
											@click="
												() => {
													if (showOther.includes(inx)) {
														showOther = showOther.filter((i) => i !== inx);
													} else {
														showOther.push(inx);
													}
												}
											"
										></i>
									</td>
								</tr>
								<tr v-show="showOther.includes(inx)">
									<!-- <td style="text-align: right; background: #f6f8fa"></td> -->
									<td style="line-height: 20px; text-align: justify; padding: 10px; background-color: #fff" colspan="6">
										<el-collapse-transition>
											<div style="width: 100%" v-show="showOther.includes(inx)">
												<div class="details_item">
													<div>
														<div class="detailTitle">
															<div class="titleIcon"></div>
															<div>教育经历</div>
														</div>
														<table cellspacing="0" cellpadding="0">
															<thead style="background: #f6f8fa">
																<td style="text-align: center">毕业年月</td>
																<td style="text-align: center">学历</td>
																<td style="text-align: center">毕业院校</td>
																<td style="text-align: center">专业</td>
															</thead>
															<tr v-for="(_item, index) in item.staffEducationDtoList" :key="index">
																<td>{{ _item.graduationTime }}</td>
																<td>{{ _item.educationName }}</td>
																<td>{{ _item.graduatedSchool }}</td>
																<td>{{ _item.profession }}</td>
															</tr>
														</table>
													</div>
													<div>
														<div class="detailTitle">
															<div class="titleIcon"></div>
															家庭状况
														</div>
														<table cellspacing="0" cellpadding="0">
															<thead style="background: #f6f8fa">
																<td style="text-align: center">关系</td>
																<td style="text-align: center">姓名</td>
																<!-- <td>出生日期</td> -->
																<td style="text-align: center">紧急联系人与否</td>
																<td style="text-align: center">家庭电话</td>
															</thead>
															<tr v-for="(_item, index) in item.staffRelativesContactInfoList" :key="index">
																<td style="height: 50px">{{ _item.relationshipName }}</td>
																<td style="height: 50px">{{ _item.name }}</td>
																<!-- <td>{{item.birthday}}</td> -->
																<td style="height: 50px">{{ _item.urgentName }}</td>
																<td style="height: 50px">{{ _item.telOne }}</td>
															</tr>
														</table>
													</div>
												</div>
												<div class="details_item">
													<div>
														<div class="detailTitle">
															<div class="titleIcon"></div>
															紧急联络
														</div>
														<table cellspacing="0" cellpadding="0">
															<thead style="background: #f6f8fa">
																<td style="text-align: center">关系</td>
																<td style="text-align: center">姓名</td>
																<td style="text-align: center">主要联系电话</td>
																<td style="text-align: center">联系电话2</td>
															</thead>
															<tr>
																<td>{{ item.staffBasicInfoDto.relationshipName }}</td>
																<td>{{ item.staffBasicInfoDto.name }}</td>
																<td>{{ item.staffBasicInfoDto.telOne }}</td>
																<td>{{ item.staffBasicInfoDto.telTwo }}</td>
															</tr>
														</table>
													</div>
													<div>
														<div class="detailTitle">
															<div class="titleIcon"></div>
															培训内容
														</div>
														<table cellspacing="0" cellpadding="0">
															<thead style="background: #f6f8fa">
																<td style="text-align: center">开始时间</td>
																<td style="text-align: center">结束时间</td>
																<td style="text-align: center">培训课程</td>
																<td style="text-align: center">考核结果</td>
															</thead>
															<tr v-for="(_item, index) in item.staffTrainingRecordsDtoList" :key="index">
																<td>{{ _item.traStartTime }}</td>
																<td>{{ _item.traEndTime }}</td>
																<td>{{ _item.traCourse }}</td>
																<td>{{ _item.exaResults }}</td>
															</tr>
														</table>
													</div>
												</div>
												<div class="details_item">
													<div>
														<div class="detailTitle">
															<div class="titleIcon"></div>
															证书
														</div>
														<table cellspacing="0" cellpadding="0">
															<thead style="background: #f6f8fa">
																<td style="text-align: center">资格证</td>
																<td style="text-align: center">等级</td>
																<!-- <td style="text-align: center">分数</td>
															<td style="text-align: center">颁发时间</td> -->
																<!-- <td>结束时间</td> -->
															</thead>
															<tr v-for="(_item, index) in item.staffCertificateRecordList" :key="index">
																<td>{{ _item.cerName }}</td>
																<td>{{ _item.grade }}</td>
																<!-- <td>{{ _item.score || '' }}</td>
															<td>{{ _item.startTime }}</td> -->
																<!-- <td>{{_item.endTime}}</td> -->
															</tr>
														</table>
													</div>
													<div>
														<div class="detailTitle">
															<div class="titleIcon"></div>
															奖励/惩罚
														</div>
														<table cellspacing="0" cellpadding="0">
															<thead style="background: #f6f8fa">
																<td style="text-align: center">日期</td>
																<td style="text-align: center">区分（奖励/惩罚）</td>
																<td style="text-align: center">原因</td>
															</thead>
															<tr v-for="(_item, i) in item.staffRewardPunishList" :key="i">
																<td>{{ _item.createTime }}</td>
																<td>{{ rewardPunishType[_item.type] }}</td>
																<td>{{ _item.reason }}</td>
															</tr>
														</table>
													</div>
												</div>
												<div class="details_item">
													<div style="width: 100%; margin-right: 0">
														<div class="detailTitle">
															<div class="titleIcon"></div>
															工作经历
														</div>
														<table cellspacing="0" cellpadding="0">
															<thead style="background: #f6f8fa">
																<td style="text-align: center">开始时间</td>
																<td style="text-align: center">结束时间</td>
																<td style="text-align: center">公司名称</td>
																<td style="text-align: center">部门</td>
																<td style="text-align: center">职位</td>
															</thead>
															<tr v-for="(_item, index) in item.staffWorkExperienceDtoList" :key="index">
																<td>{{ _item.workStartTime }}</td>
																<td>{{ _item.workEndTime }}</td>
																<td>{{ _item.company }}</td>
																<td>{{ _item.departmentName }}</td>
																<td>{{ _item.officeName }}</td>
															</tr>
														</table>
													</div>
												</div>
												<div class="details_item">
													<div style="width: 100%; margin-right: 0">
														<div class="detailTitle">
															<div class="titleIcon"></div>
															成长记录
														</div>
														<table cellspacing="0" cellpadding="0">
															<thead style="background: #f6f8fa">
																<td style="text-align: center">命令日期</td>
																<td style="text-align: center">人事命令</td>
																<td>描述</td>
															</thead>
															<tr v-for="(_item, index) in item.growthRecordList" :key="index">
																<td style="line-height: 30px; text-align: center">{{ _item.time }}</td>
																<td style="line-height: 30px; text-align: center">
																	{{ _item.growthTypeName }}
																</td>
																<td
																	v-html="_item.remark"
																	style="line-height: 20px; padding: 10px; text-align: justify"
																></td>
															</tr>
														</table>
													</div>
												</div>
											</div>
										</el-collapse-transition>
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</template>
			<empty v-else />
		</div>
	</div>
</template>

<script>
import * as api from '@/api/workbench';
import formItem from '@/views/components/components-form-item/index.vue';
import empty from '@/views/components/components-empty/index.vue';
import { Loading } from 'element-ui';
export default {
	components: { formItem, empty },
	props: { pageName: {} },
	computed: {
		rewardPunishType() {
			const arr = this.$store.state.app.dict.filter((item) => item.groupId === 'rewardPunishType');

			const map = {};

			arr.forEach((v) => {
				map[v.dictId] = v.dictName;
			});
			return map;
		}
	},
	data() {
		return {
			emergencyContact: [],

			staffInfo: {
				default() {
					return {
						items: ''
					};
				}
			},
			staffJobInfo: {},
			growthRecord: [],
			staffRewardPunishRecord: [],
			staffId: '', // 员工id
			showOther: [],
			formModel: {},
			formItems: [
				{
					label: '姓名',
					key: 'name',
					type: 'input',
					span: 4,
					labelWidth: '60px'
				},
				{
					label: '组织',
					key: 'orgId',
					type: 'tree',
					async: () => this.$axios.post('/v1/org/list', { request: {} }),
					props: { children: 'children', label: 'orgName' },
					nodeKey: 'id',
					nodeName: 'orgName',
					showKey: 'orgName',
					filter: true,
					span: 4,
					labelWidth: '60px'
				},
				{
					label: '工号',
					key: 'jobNumber',
					type: 'input',
					span: 4,
					labelWidth: '60px'
				},
				{
					label: '职位',
					key: 'officeName',
					type: 'input',
					span: 4,
					labelWidth: '60px'
				},
				{
					label: '手机号',
					key: 'phoneNumber',
					type: 'input',
					span: 5,
					labelWidth: '70px'
				},
				{
					span: 3,
					labelWidth: '0px',
					style: 'min-width: 100px',
					render: () => (
						<el-button size="small" type="primary" style="margin-top: 5px" icon="el-icon-search" onclick={this.getCardList}>
							查询
						</el-button>
					)
				}
			],
			allDataList: [
				{
					growthRecordList: [],
					staffBasicInfoDto: {},
					staffBasicJobDetailDto: {},
					staffCertificateRecordList: [],
					staffEducationDtoList: [],
					staffProjectExperienceDtoList: [],
					staffRelativesContactInfoList: [],
					staffRewardPunishList: [],
					staffTrainingRecordsDtoList: [],
					staffWorkExperienceDtoList: []
				}
			]
		};
	},

	mounted() {
		this.$nextTick(() => {
			this.getCardList();

			// this.getStaffInfoDetail();
			// this.getStaffJobInfoDetail();
			// this.getGrowthRecord();
			// this.getRewardsAndPunishments();
		});
	},

	methods: {
		// 人事卡
		getCardList() {
			const loading = Loading.service({ target: '.viewboxDetail' });

			this.$axios
				.post('/v1/org/queryPersonnelCardInfo', {
					request: {
						pageNo: 1,
						pageSize: 1,
						...this.formModel
					}
				})
				.then((res) => {
					loading.close();
					if (res?.code) {
						return;
					}
					this.allDataList = res;
				})
				.catch(() => {
					loading.close();
				});
		},
		// 个人信息
		getStaffInfoDetail() {
			api.getStaffInfoDetail({
				request: {
					id: this.$route.query.id || '885'
				}
			})
				.then((result) => {
					this.staffInfo = result;
					this.staffId = this.staffInfo.staffBasicInfoDto.staffId;
				})
				.catch(() => {});
		},
		// 岗位信息
		getStaffJobInfoDetail() {
			api.getStaffJobInfoDetail({
				request: {
					id: this.$route.query.id || '885'
				}
			})
				.then((result) => {
					this.staffJobInfo = result;
				})
				.catch(() => {});
		},
		// 成长记录
		getGrowthRecord() {
			api.getGrowthRecord({
				request: {
					id: this.$route.query.id || '885'
				}
			})
				.then((result) => {
					this.growthRecord = result;
				})
				.catch(() => {});
		},
		getRewardsAndPunishments() {
			api.getEmployeeRewardsAndPunishments({
				id: this.$route.query.id || '885'
			}).then((res) => {
				if (res._responseStatusCode === 0) {
					this.staffRewardPunishRecord = res.staffRewardPunishRecord || [];
					if (res.staffRelativesContactInfo && res.staffRelativesContactInfo.length > 0) {
						this.emergencyContact = res.staffRelativesContactInfo.filter((v) => Number(v.isUrgent) === 1);
					} else {
						this.emergencyContact = [];
					}
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.humanaffairs-info .el-tabs__header {
	margin-bottom: 0px;
}
.humanaffairs-info .input_textarea .el-form-item__content {
	width: 80% !important;
}
.humanaffairs-info .el-form-item__label {
	font-weight: normal;
}

.el-textarea .el-input__count {
	background: none;
}

.work {
	.el-form-item__content {
		width: 80% !important;
	}
}
</style>
<style lang="scss">
.input_area .el-input__inner {
	padding: 0 50px 0 15px;
}
.editTreeselect {
	.el-input .el-input__inner {
		height: 40px;
	}
}

.assessBox .app-container .viewboxDetail .level_box_now {
	font-weight: bold;
}

.assessBox {
	.app-container {
		color: #484848;
		padding: 0;
		background-color: #f6f7fb;
		.titlebox {
			text-align: center;
			line-height: 17px;
			background: #fff;
			font: bold 18px/25px 'Microsoft YaHei';
			padding: 14px 40px;
			> .el-button {
				float: left;
				color: #484848;
				transform: translateY(-8px);
				font-size: 16px;
			}
			text-align: center;
			line-height: 17px;
			font: bold 18px/25px 'Microsoft YaHei';
			margin: 20px;
		}

		.viewboxDetail {
			padding: 0px;
			// margin: 0 20px;
			.el-tabs__active-bar {
				display: none;
			}
			.el-tabs__nav {
				float: none;
				text-align: center;
				background: #f6f7fb;
				padding: 10px 0px;
			}
			.el-tabs__item {
				font-size: 18px;
				font-weight: bold;
			}
			.el-tabs__content .el-tab-pane .el-collapse {
				border-top: none;
				border-bottom: none;
				margin-bottom: 10px;
			}
			.el-collapse-item__header {
				margin: 5px 0px 0px 0px;
				border-bottom: none;
				font-size: 16px;
				font-weight: bold;
				padding: 0px 15px;
				height: 75px;
			}
		}
	}
}
.baseTable {
	display: flex;
	margin-top: 10px;
	> table {
		width: 100%;
		min-width: 500px;
		border: 1px solid #b0bacb;
		:first-child {
			td:nth-child(even) {
				text-align: right;
				background: #f6f8fa;
				padding: 10px;
			}
			td:nth-child(odd) {
				background: #fff;
				text-align: left;
				padding: 10px;
				// min-width: 180px;
			}
		}
		tr {
			line-height: 2.3;
			.pic {
				background: #ffffff !important;
				padding: 0px !important;
				width: 100%;
				img {
					width: 100%;
					height: 100%;
				}
				p {
					text-align: center;
					margin-bottom: 0;
					margin-top: 0;
				}
			}
		}
		td {
			border: 1px solid #b0bacb;
			vertical-align: middle;
		}
		td:nth-child(odd) {
			text-align: right;
			background: #f6f8fa;
			// width: 14%;
			padding-right: 10px;
		}
		td:nth-child(even) {
			text-align: left;
			padding-left: 10px;
		}
	}
}
.details_item {
	display: block;
	margin-top: 10px;
	.detailTitle {
		display: flex;
		// justify-conten:center;
		height: 30px;
		font-size: 10px;
		font-size: 18px;
	}
	div {
		display: inline-block;
		width: calc(100% / 2 - 10px);
	}
	div:first-child {
		margin-right: 20px;
	}
	table {
		width: 100%;
		height: 150px;
		border: 1px solid #b0bacb;
		thead {
			td {
				height: 50px;
			}
		}
		td {
			border: 1px solid #b0bacb;
			word-break: break-all;
			padding-left: 10px;
			vertical-align: middle;
		}
	}
}
.titleIcon {
	display: inline-block;
	width: 8px !important;
	height: 20px;
	background: #498df0;
	border-radius: 5px;
	vertical-align: bottom;
	margin-right: 5px !important;
}
@media print {
	#printPage1 {
		.pic {
			// display: none;
		}
		padding: 0 !important ;
		background-color: #fff;
		td {
			text-align: center !important;
			font-size: 8px;
			background-color: #f6f8fa;
			// min-width: 100px;
		}
		> table {
			> tr > td {
				min-width: 100px;
			}
		}
		.details_item .detailTitle {
			// display: flex;
			// justify-conten:center;
			// align-items: center;
			height: 30px;
			font-size: 10px;
		}
	}
}
</style>
