<template>
	<div class="app-container">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="异动统计" name="1" lazy v-if="permissionControl('JobTransferStatistics')">
				<transaction ref="reportFormList1" :activeTab="activeName" v-if="activeName === '1'" pageName="JobTransferStatistics" />
			</el-tab-pane>
			<el-tab-pane label="离职统计" name="2" lazy v-if="permissionControl('ResignationStatistics')">
				<quit ref="reportFormList2" :activeTab="activeName" v-if="activeName === '2'" pageName="ResignationStatistics" />
			</el-tab-pane>
			<el-tab-pane label="在职统计" name="3" lazy v-if="permissionControl('JobStatistics')">
				<incumbency ref="reportFormList3" :activeTab="activeName" v-if="activeName === '3'" pageName="JobStatistics" />
			</el-tab-pane>
			<!-- <el-tab-pane label="薪酬统计" name="4" lazy v-if="permissionControl('/v1/overview/human/resource/cost/analysis')">
				<cost ref="reportFormList4" :activeTab="activeName" v-if="activeName === '4'" />
			</el-tab-pane> -->
			<!-- <el-tab-pane label="绩效统计" name="5">
				<performanceStatistics ref="reportFormList5" :activeTab="activeName" v-if="activeName === '5'" />
			</el-tab-pane> -->
			<el-tab-pane label="考勤统计" name="6" v-if="permissionControl('AttendanceStatistics')">
				<attendanceAll ref="reportFormList6" :activeTab="activeName" v-if="activeName === '6'" pageName="AttendanceStatistics" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
// import abnormalReport from './reportForm/abnormalReport.vue'; // 异动统计
// import performanceStatistics from './reportForm/performanceStatistics.vue'; // 绩效
// import turnoverStatistics from './reportForm/turnoverStatistics.vue';
import quit from '../../../overview/management/page/quit.vue';
import transaction from '../../../overview/management/page/transaction.vue';
import incumbency from '../../../overview/management/page/incumbency.vue';
// import cost from '../../../overview/management/page/cost.vue';
import attendanceAll from '../../../attendanceManage/attendanceAll.vue';
// import orgConfig from '../orgConfig.vue';
export default {
	name: 'reportFormList',
	components: { quit, transaction, incumbency, attendanceAll },
	props: { pageName: {} },
	data() {
		return { activeName: '' };
	},

	mounted() {
		const data = JSON.parse(sessionStorage.getItem('reportFormListData'));
		const { activeName, listQuery } = data || {};

		this.activeName = activeName || '1';
		if (listQuery) {
			this.listQuery = listQuery;
		}
		// 设置tab默认
		this.setActiveTab(this.pageName, [
			{ name: '1', key: 'JobTransferStatistics' },
			{ name: '2', key: 'ResignationStatistics' },
			{ name: '3', key: 'JobStatistics' },
			{ name: '6', key: 'AttendanceStatistics' }
		]);
	},
	methods: {
		handleClick() {
			sessionStorage.setItem(
				'reportFormListData',
				JSON.stringify({
					activeName: this.activeName
				})
			);
		}
	}
};
</script>

<style lang="scss" scoped></style>
