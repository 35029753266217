import { render, staticRenderFns } from "./personnelCard.vue?vue&type=template&id=0a46d538&scoped=true&"
import script from "./personnelCard.vue?vue&type=script&lang=js&"
export * from "./personnelCard.vue?vue&type=script&lang=js&"
import style1 from "./personnelCard.vue?vue&type=style&index=1&id=0a46d538&prod&lang=scss&"
import style2 from "./personnelCard.vue?vue&type=style&index=2&id=0a46d538&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a46d538",
  null
  
)

export default component.exports