<template>
	<div class="app-container">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="花名册" name="1" v-if="permissionControl(tabKey('1'))">
				<roster ref="personnelList1" :index="2" :activeTab="activeName" v-if="activeName === '1'" :pageName="tabKey('1')" />
			</el-tab-pane>
			<el-tab-pane label="人事卡" name="2" v-if="permissionControl(tabKey('2'))">
				<personnelCard ref="personnelList2" :activeTab="activeName" v-if="activeName === '2'" :pageName="tabKey('2')" />
			</el-tab-pane>
			<el-tab-pane label="证件信息" name="3" v-if="permissionControl(tabKey('3'))">
				<idInfo ref="personnelList3" :activeTab="activeName" v-if="activeName === '3'" :pageName="tabKey('3')" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import roster from './personnel/roster.vue';
import personnelCard from './personnel/personnelCard.vue';
import idInfo from './personnel/idInfo.vue';
// import orgConfig from '../orgConfig.vue';
export default {
	name: 'personnelList',
	components: { roster, personnelCard, idInfo },
	props: { pageName: {} },
	data() {
		return { activeName: '' };
	},
	computed: {
		isSet() {
			return this.pageName === 'PersonnelDetailInfoSet';
		},
		tabList() {
			let list = [
				{ name: '1', key: 'Roster' },
				{ name: '2', key: 'PersonnelCard' },
				{ name: '3', key: 'CertificateInformation' }
			];

			if (this.isSet) {
				list = [
					{ name: '1', key: 'RosterSet' },
					{ name: '2', key: 'PersonnelCardSet' },
					{ name: '3', key: 'CertificateInformationSet' }
				];
			}
			return list;
		},
		tabKey() {
			return (inx) => this.tabList.find(({ name }) => name === inx)?.key;
		}
	},
	mounted() {
		const data = JSON.parse(sessionStorage.getItem('personnelListData'));
		const { activeName, listQuery } = data || {};

		this.activeName = activeName || '1';
		if (listQuery) {
			this.listQuery = listQuery;
		}
		// 设置tab默认
		this.setActiveTab(this.pageName, this.tabList);
	},
	methods: {
		handleClick() {
			sessionStorage.setItem(
				'personnelListData',
				JSON.stringify({
					activeName: this.activeName
				})
			);
		}
	}
};
</script>

<style lang="scss" scoped></style>
