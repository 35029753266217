<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane label="人员信息" name="1" v-if="permissionControl('PersonnelDetailInfo')">
				<personnelList ref="information1" :activeTab="activeName" v-if="activeName === '1'" pageName="PersonnelDetailInfo" />
			</el-tab-pane>
			<el-tab-pane label="人事报表" name="2" v-if="permissionControl('PersonnelReport')">
				<reportFormList ref="information2" :activeTab="activeName" v-if="activeName === '2'" pageName="PersonnelReport" />
			</el-tab-pane>
			<el-tab-pane label="人员信息设置" name="3" v-if="permissionControl('PersonnelDetailInfoSet')">
				<personnelList ref="information3" :activeTab="activeName" v-if="activeName === '3'" pageName="PersonnelDetailInfoSet" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import personnelList from './personnelList.vue';
import reportFormList from './reportFormList.vue';
// import orgConfig from '../orgConfig.vue';
export default {
	name: 'informationPage',
	components: { personnelList, reportFormList },

	data() {
		return { activeName: '' };
	},

	mounted() {
		const data = JSON.parse(sessionStorage.getItem('informationData'));
		const { activeName, listQuery } = data || {};

		this.activeName = activeName || '1';
		if (listQuery) {
			this.listQuery = listQuery;
		}
		// 设置tab默认
		this.setActiveTab(this.$route.name, [
			{ name: '1', key: 'PersonnelDetailInfo' },
			{ name: '2', key: 'PersonnelReport' },
			{ name: '3', key: 'PersonnelDetailInfoSet' }
		]);
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			'informationData',
			JSON.stringify({
				activeName: this.activeName
				// listQuery: this.$refs[`information${this.activeName}`].listQuery
			})
		);
		next();
	}
};
</script>

<style lang="scss" scoped></style>
