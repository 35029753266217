<template>
	<div class="container">
		<div style="margin: 10px auto; width: 100%">
			<el-col :span="4">
				<div class="inputBox">
					<span style="width: 50px">姓名</span>
					<el-input v-model="params.name" size="small" style="width: 200px"></el-input>
				</div>
			</el-col>
			<!-- <el-col :span="5">
          <div class="inputBox">
            <span >组织名称</span>
            <treeselect
              :props="depProps"
              :options="deps"
              :value="depsId"
              :clearable="isClearable"
              :accordion="isAccordion"
              :level="depsId"
              @getValue="getValue($event)"
              style="width:100%"
          />
          </div>
        </el-col>-->
			<el-col :span="5">
				<div class="inputBox">
					<span>证件类型</span>
					<el-select style="width: 200px" clearable v-model="params.cerType" size="small">
						<el-option v-for="(item, index) in licenseCerList" :key="index" :label="item.dictName" :value="item.dictId"></el-option>
					</el-select>
				</div>
			</el-col>

			<el-col :span="5">
				<div class="inputBox">
					<span>证照名称</span>
					<el-input v-model="params.licenseName" size="small" style="width: 200px"></el-input>
				</div>
				<!-- <div class="inputBox">
					<span>修改日期</span>
					<el-date-picker
						v-model="params.updateTimeBefore"
						value-format="yyyy-MM-dd"
						format="yyyy-MM-dd"
						size="small"
						type="date"
						@change="startChange"
						style="margin-right: 5px; width: 300px"
					></el-date-picker
					>-
					<el-date-picker
						v-model="params.updateTimeEnd"
						value-format="yyyy-MM-dd"
						format="yyyy-MM-dd"
						type="date"
						@change="endChange"
						size="small"
						style="margin-left: 5px; width: 300px"
					></el-date-picker>
				</div> -->
			</el-col>
			<el-button type="primary" size="small" icon="el-icon-search" style="margin-left: 5px" @click="handleSearch()">查询</el-button>
			<el-button
				type="primary"
				size="small"
				icon="el-icon-upload2"
				style="float: right"
				@click="handleModal"
				v-if="permissionControlBtns(pageName, 'Enter') && !isView"
				>录入</el-button
			>
		</div>
		<div style="width: 100%">
			<el-table
				:data="tableData.list"
				slot="empty"
				v-loading="loadding"
				class="tableBackground"
				style="width: 100%"
				@row-click="handleInfoClick"
			>
				<!-- <el-table-column
            prop="staffId"
            label="序号"
            width="50px"
            >
          </el-table-column>-->
				<el-table-column type="index" label="序号" :width="80" align="center" :index="indexMethod"></el-table-column>
				<el-table-column prop="name" align="center" label="姓名"></el-table-column>
				<el-table-column prop="phoneNumber" align="center" label="手机号码" width="180px"></el-table-column>
				<el-table-column prop="jobNumber" align="center" label="工号"></el-table-column>
				<!-- <el-table-column prop="hireFormName" align="center" label="聘用形式"></el-table-column> -->
				<el-table-column prop="orgName" align="center" label="组织"></el-table-column>
				<el-table-column prop="officeName" align="center" label="职位"></el-table-column>
				<el-table-column prop="jobName" align="center" label="岗位"></el-table-column>
				<el-table-column prop="typeName" align="center" label="证照类型"></el-table-column>
				<!-- <el-table-column prop="licenseName" align="center" label="证照名称"></el-table-column> -->
				<!-- <el-table-column prop="updateTime" align="center" label="修改时间" width="180px"></el-table-column> -->
				<el-table-column align="center" label="操作" width="130px" v-if="!isView">
					<template v-slot="{ row }" v-if="permissionControlBtns(pageName, 'Delete')">
						<!--<el-dropdown>!-->
						<el-button
							type="text"
							v-for="(item, i) in commands"
							size="small"
							:key="i"
							:style="item.label == '删除' ? 'color:#f56c6c' : ''"
							@click.stop="handleRowClick(row, item)"
							>{{ item.label }}</el-button
						>
						<!--<el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item, i) in commands"
                                    @click.native.stop="handleRowClick(row,item)"
                                    :command="item"
                                    :key="i">{{item.label}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>!-->
					</template>
					<!-- <template slot-scope="scope">
              <el-button @click="handleInfoClick(scope.row)" type="text" size="small">详情</el-button>
              <el-button style="color:red" type="text" size="small" @click="handleDelClick(scope.row)">删除</el-button>
            </template>-->
				</el-table-column>
			</el-table>
		</div>

		<div style="width: 100%; float: right">
			<el-pagination
				class="fr"
				style="margin: 20px 0"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pagenation.pageNo"
				:page-sizes="[20, 50, 100, 200, 300, 400]"
				:page-size="pagenation.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="pagenation.totalNum"
			></el-pagination>
		</div>
		<el-dialog title="提示" :visible.sync="delVisible" width="300px">
			<span>是否确定删除？</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="delVisible = false">取 消</el-button>
				<el-button type="primary" @click="handleDelRow">确 定</el-button>
			</span>
		</el-dialog>
		<entryCertificateModal @handleSearch="handleSearch" :show.sync="show" :licenseParams="licenseParams"></entryCertificateModal>
	</div>
</template>

<script>
import * as api from '@/api/workbench';
// import treeselect from "@/components/treeSelect/treeSelect"
import entryCertificateModal from './entryCertificateModal.vue';
export default {
	name: 'reward',
	components: {
		//   treeselect,
		entryCertificateModal
	},

	props: {
		index: { type: String, default: () => '' },
		pageName: {}
	},
	computed: {
		isView() {
			return this.pageName === 'CertificateInformation';
		}
	},
	data() {
		return {
			// 分页参数
			pagenation: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			levelList: [],
			// 搜索条件对象
			params: {
				name: '',
				licenseName: '',
				updateTimeBefore: '',
				updateTimeEnd: '',
				pageNo: 1,
				pageSize: 20
			},
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			tableData: [],
			tabIndex: '',
			loadding: false,
			show: false,
			rowId: 0,
			licenseCerList: [],
			deps: this.$store.state.setData.depsList,
			depsId: '',
			isClearable: true, // 可清空（可选）
			isAccordion: true,
			staffList: [],
			dialogImageUrl: '',
			licenseParams: {
				id: '',
				staffId: '',
				orgId: '',
				type: 2,
				cerType: '',
				licenseId: '',
				licenseName: '',
				licenseUrl: ''
			},
			delVisible: false,
			delRowId: 0,
			commands: [
				// {
				// 	label: '详情',
				// 	value: '1'
				// },
				{
					label: '删除',
					value: '2'
				}
			]
		};
	},
	created() {},
	mounted() {
		this.$nextTick(() => {
			this.licenseCerList = this.$store.state.app.dict.filter((item) => item.groupId == 'licenseCerType');
			this.handleSearch();
		});
	},
	methods: {
		// 排序
		indexMethod(index) {
			return index + (this.pagenation.pageNo - 1) * this.pagenation.pageSize + 1;
		},
		handleModal() {
			this.show = true;
		},
		handleRowClick(row, command) {
			// this.rowId = row.id
			if (command.value == '1') {
				this.handleInfoClick(row);
			} else if (command.value == '2') {
				this.handleDelClick(row);
			}
		},
		// 部门选中的值
		getValue(eve) {
			this.depsId = eve.id;
			this.params.orgId = eve.id;
		},
		startChange(val) {
			this.params.updateTimeBefore = `${val} 00:00:00`;
		},
		endChange(val) {
			this.params.updateTimeEnd = `${val} 23:59:59`;
		},
		handleSearch() {
			this.loadding = true;

			const params = {
				request: this.params
			};

			api.queryLicence(params)
				.then((result) => {
					this.tableData = result;
					this.pagenation.totalNum = result.totalNum;
					this.loadding = false;
					this.show = false;
				})
				.catch(() => {});
		},

		// 获取管理等级
		handleChange() {
			this.params.codeName = this.$refs.cascader.getCheckedNodes()[0].pathLabels[2];
		},
		handleInfoClick(row) {
			this.rowId = row.staffId;
			this.$router.push({
				path: '/personalInfo',
				query: {
					id: this.rowId,
					type: 2,
					index: this.isView && 3
				}
			});
		},
		handleSizeChange(val) {
			this.pagenation.pageSize = val;
			this.params.pageSize = val;
			this.handleSearch();
		},
		handleCurrentChange(val) {
			this.pagenation.pageNo = val;
			this.params.pageNo = val;
			this.handleSearch();
		},
		handleDelClick(row) {
			this.$confirm(`您确认删除${row.name}的证照信息吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.handleDelRow(row.staffId, row.id);
			});
		},
		handleDelRow(staffId, id) {
			const params = {
				request: {
					id,
					staffId
				}
			};

			api.deleteLicence(params)
				.then((result) => {
					if (result.code == 0) {
						this.delVisible = false;
						this.handleSearch();
					}
				})
				.catch(() => {});
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	display: flex;
	flex-wrap: wrap;
}
.toolBox {
	position: absolute;
	top: 7px;
	right: 0;
	margin-bottom: 10px;
	div {
		float: right;
	}
}
// .inputBox{
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 10px;
//     span{
//       width: 100px;
//       text-align: right;
//       margin-right: 10px
//     }
//   .addBTn{
//     width: 40px;
//     height: 40px;
//     background: #fff;
//   }
// }
</style>
<style lang="scss">
.textarea {
	width: 100%;
	.el-form-item__content {
		width: 80%;
	}
}
</style>
